import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios'
import { BASE_URL, PLAYER_LIST, LEAVE_TEAM } from "../API";
import { toast } from "react-toastify";
import moment from "moment";
import { Modal } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

function OtherTeamsView({ selectedTeam }) {
  const navigate = useNavigate();
  const [playerLobbyList, setPlayerLobbyList] = useState([]);
  const [captainDetails, setCaptainDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [modalId, setModalId] = useState(null);
  const [showRemoveModal, setShowRemove] = useState(false);
  const [isLocked, setIsLocked] = useState(false)

  useEffect(() => {
    getPlayerLobby();
    if (selectedTeam.status === 'lock') { setIsLocked(true) }
  }, []);

  const getPlayerLobby = async () => {
    const token = localStorage.getItem('token')
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const response = await axios.get(BASE_URL + PLAYER_LIST + '?team_id=' + selectedTeam.id, {
        headers: headers,
      });
      console.log(response.data.players)
      if (response.data.status_code === 200 && response.data.status === true) {
        setPlayerLobbyList(response.data.players)
        console.log('========>', playerLobbyList);
        //   toast.success(response.data.message);
      } else if (
        response.data.status_code === 400 &&
        response.data.status === false
      ) {
        toast.error(response?.data?.error[0]);
      } else if (
        response.data.status_code === 403 &&
        response.data.status === false
      ) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
      } else {
        // console.log('response.data::',response)
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message || "An error occurred";
        toast.error(errorMessage);
      } else {
        toast.error("An error occurred");;
      }
    }
  }

  const exitTeam = async (id) => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const response = await axios.post(BASE_URL + LEAVE_TEAM, {
        team_id: id,
      }, {
        headers: headers
      });

      if (response.data.status_code === 200 && response.data.status === true) {
        toast.success(response.data.message);
        getPlayerLobby();
        navigate("/myprofile", { state: { menu: 1 } });
      } else if (
        response.data.status_code === 400 &&
        response.data.status === false
      ) {
        toast.error(response?.data?.error[0]);
        setIsLoading(false);
      } else if (
        response.data.status_code === 403 &&
        response.data.status === false
      ) {
        setIsLoading(false);
      } else {
        toast.error(response.data.message);
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message || "An error occurred";
        toast.error(errorMessage);
        setIsLoading(false);
      } else {
        toast.error("An error occurred");
        setIsLoading(false);
      }
    }
  }

  const exitModal = (id) => {
    setModalId(id);
    setShowRemove(true);
  }
  return (
    <>
      <section>
        <div className="edit-profile-main">
          <div className="upper-form-main">
            <div className="form-main-name-detal team-detail-main">
              <div className="team-name-all">
                <div className="team-name">
                  <h6>Team Name</h6>
                  <p>{selectedTeam.team_name}</p>
                </div>

                <div className="team-name">
                  <h6>Total Players</h6>
                  <p>{selectedTeam.total_players} Players</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>

      <section className='view-table-main'>
        <div className="upper-btn-create d-flex">
          <h3 className='main-head-pro'>Players Lobby</h3>
          {isLocked ?
            (<>
              <div className="upper-div-lock">
                <div className="upper-back-color">
                  <FontAwesomeIcon icon={faLock} />
                </div>
                <button className="edit-butt justify-content-center border-bg text-center" disabled >Exit Team</button>
              </div>
            </>
            ) : (<button className="edit-butt justify-content-center border-bg text-center"
              onClick={() => exitModal(selectedTeam.id)} >Exit Team</button>)
          }
        </div>
        <div className="table-maintourna table-palyer">
          <table className='w-100'>
            <thead>
              <tr>
                <th>Player Name</th>
                <th>Role</th>
                <th>PSN ID</th>
                <th>Activision ID</th>
                <th>Xbox GamerTag</th>
                <th>EA ID</th>
                <th>Ubisoft ID</th>
                <th>Discord ID</th>
              </tr>
            </thead>
            <tbody>
              {playerLobbyList.map((player, index) => (
                <tr key={index}>
                  <td>{player.name}</td>
                  <td>{player.is_accepted == 2 ? 'Captain' : 'Player'}</td>
                  <td>{player.user_details.psn ? player.user_details.psn : '-'}</td>
                  <td>{player.user_details.activision ? player.user_details.activision : '-'}</td>
                  <td>{player.user_details.xbox ? player.user_details.xbox : '-'}</td>
                  <td>{player.user_details.ea ? player.user_details.ea : '-'}</td>
                  <td>{player.user_details.ubisoft ? player.user_details.ubisoft : '-'}</td>
                  <td>{player.user_details.discord ? player.user_details.discord : '-'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>

      <Modal className="modal-logout" show={showRemoveModal} onHide={() => setShowRemove(false)}>
        <Modal.Body className="modal-padd">
          <div className="inner-ogout-btn">
            <h2>Are You sure you want to leave the team?</h2>
            <p className="text-damble-text">
              The action you are going to perform is irreversible please
              Confirm! Are you sure that you want to proceed?
            </p>
          </div>
          <div className="upper-btn-llogout-main d-flex">
            <Link onClick={() => setShowRemove(false)} className="login-head">
              Close
            </Link>
            <a className="edit-butt" onClick={() => { exitTeam(modalId); setShowRemove(false) }}>
              Exit
            </a>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
export default OtherTeamsView