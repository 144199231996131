import React, { useState, useEffect,useRef } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import redlogo from "../Assets/Images/redogo.png";
import backi from "../Assets/Images/bacr.png";
import logcomp from "../Assets/Images/eu.png";
import Pagination from 'react-bootstrap/Pagination';


import {
  CREDITS_PACKAGE,
  BASE_URL,
  IMAGE_BASE_URL,
  USER_CREDIT_TRANSACTIONS,
  SAVE_TRANSACTION,
} from "../API";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import axios from "axios"; // Make sure axios is imported
import userImage from "../assets/user.png";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import moment from "moment";
const ITEMS_PER_PAGE = 5;

const PayPalConfig = {
  "client-id":
  "AftyrcHcbyn1yHRLbRiHCGFjRrtyQKt3TmOjvtNWsJVw89Ualqe5pNPtuQ1Q9JsZ77iMmwhYFoXMvT_W",
  "currency": "GBP"
};

function Credits({ userDetails, getUserDetails, openModel }) {
  const [isLoading, setIsLoading] = useState(false);
  const [creditPurchaseHistory, setCreditPurchaseHistory] = useState([]);
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState();
  const [creditPackage, setCreditPackage] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const paypalKeyRef = useRef(0); // Ref to hold key
  const [error, setError] = useState(null);
  
  // const handlePackageSelect = (data) => {
    //   setSelectedPackage(data); 
    //   console.log(data)
    // };
    const resetPaypalKey = () => {
      paypalKeyRef.current += 1;  
    };
    
    useEffect(() => {
      getCreditPurchesHistory();
      getCreditPackages();
  }, [userDetails]);

  useEffect(() => {
    getCreditPurchesHistory();
  }, [currentPage]);

  useEffect(() => {
    resetPaypalKey()
  }, [selectedPackage]);

  const getCreditPackages = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      const user = JSON.parse(localStorage.getItem("user"));
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const response = await axios.get(BASE_URL + CREDITS_PACKAGE + "?user_id=" + user.id, {
        headers: headers,
      });

      if (response.data.status_code === 200 && response.data.status === true) {
        console.log(response.data)
        setCreditPackage(response.data.data);
        setIsLoading(false);

        //   toast.success(response.data.message);
      } else if (
        response.data.status_code === 400 &&
        response.data.status === false
      ) {
        toast.error(response?.data?.error[0]);
        setIsLoading(false);
      } else if (
        response.data.status_code === 403 &&
        response.data.status === false
      ) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        // console.log('response.data::',response)
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message || "An error occurred";
        setIsLoading(false);
        toast.error(errorMessage);
      } else {
        toast.error("An error occurred");
        setIsLoading(false);
      }
    }
  };

  const createOrder = (data, actions) => {
    let amount=selectedPackage?.discounted_amount || selectedPackage?.amount || 0;
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: amount,
            currency_code: "GBP",
            breakdown: {
              item_total: {
                currency_code: "GBP",
                value: amount,
              },
            },
          },

          items: [
            {
              unit_amount: {
                currency_code: "GBP",
                value: amount,
              },
              quantity: "1",
              name:
                "GBP " +
                amount +
                " Credits " +
                amount,
            },
          ],
        },
      ],
    });
  };


  const onApprove = (data, actions) => {
    return actions.order
      .capture()
      .then(async function (details) {
        if (details && details.status === "COMPLETED") {
          const formData = {
            plan_id: selectedPackage.id,
            transaction_id: details.id,
            payment_type: data.paymentSource,
            amount: details.purchase_units[0].amount.value,
            transaction_status: details.status,
          };
          setIsLoading(true);
          try {
            const token = localStorage.getItem("token");
            const headers = {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            };

            const response = await axios.post(
              BASE_URL + SAVE_TRANSACTION,
              JSON.stringify(formData),
              { headers: headers }
            );

            if (
              response.data.status_code === 200 &&
              response.data.status === true
            ) {
              setIsLoading(false);
              toast.success(response.data.message);
              setShow(false);
              getUserDetails();
            } else if (
              response.data.status_code === 400 &&
              response.data.status === false
            ) {
              toast.error(response?.data?.error[0]);
              setIsLoading(false);
            } else if (
              response.data.status_code === 403 &&
              response.data.status === false
            ) {
              localStorage.removeItem("user");
              localStorage.removeItem("token");
              navigate("/login");
            } else {
              // console.log('response.data::',response)
              toast.error(response.data.message);
            }
          } catch (error) {
            if (error.response && error.response.data) {
              const errorMessage =
                error.response.data.message || "An error occurred";
              setIsLoading(false);
              toast.error(errorMessage);
            } else {
              toast.error("An error occurred");
              setIsLoading(false);
            }
          }
        } else {
        }
      })
      .catch((error) => {
        alert('ddd')
        console.error("Error occurred during payment approval:", error);
      });
  };

  const getCreditPurchesHistory = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const response = await axios.get(BASE_URL + USER_CREDIT_TRANSACTIONS, {
        headers: headers,
      });

      if (response.data.status_code === 200 && response.data.status === true) {
        const purchaseHistory = response.data.data;
        // console.log(purchaseHistory)
        const totalCount = purchaseHistory.length;
        setTotalPages(Math.ceil(totalCount / ITEMS_PER_PAGE));
        const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
        const currentItems = purchaseHistory.slice(startIndex, startIndex + ITEMS_PER_PAGE);
        setCreditPurchaseHistory(currentItems);
        setIsLoading(false);

        //   toast.success(response.data.message);
      } else if (
        response.data.status_code === 400 &&
        response.data.status === false
      ) {
        toast.error(response?.data?.error[0]);
        setIsLoading(false);
      } else if (
        response.data.status_code === 403 &&
        response.data.status === false
      ) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        // console.log('response.data::',response)
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message || "An error occurred";
        setIsLoading(false);
        toast.error(errorMessage);
      } else {
        toast.error("An error occurred");
        setIsLoading(false);
      }
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const onError = (err) => {
    setError(err)
  };
  return (
    <>
      <Loader isLoading={isLoading} />

      <section>
        <Container>
          <div className="edit-profile-main ">
            <h3 className="main-head-pro">Credits </h3>
            <div className="upper-form-main">
              <div className="form-main-name-detal d-flex">
                <div className="credit-inner-mg-pan">
                  <figure>
                    <img src={backi} alt="" />
                  </figure>
                  <div className="icon-cred-avail">
                    <img className="logo-plan" src={logcomp} alt="" />
                    <div className="cred-avl">
                      <p>Credits Available</p>
                      <h6>{userDetails?.user_details?.credits}</h6>
                    </div>
                  </div>
                </div>

                <div className="cred-instruction w-100">
                  <div className="upper-head-w-logo d-flex">
                    <h6>Credits Instructions</h6>
                    <img src={redlogo} alt="" />
                  </div>
                  <ul className="cred-list">
                    <li>
                      <b>Non-convertible Credits: </b> Purchased credits cannot be converted into cash.
                    </li>
                    <li>
                      <b>Exclusive Use: </b> Credits are only to be used for tournament entry fees.
                    </li>
                    <li>
                      <b>Credits Refund Policy: </b> Credits Refunds will only be issued if a tournament is canceled by the administrator.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="upper-btn-login-page text-start mt-4">
                <button className="edit-butt" onClick={() => setShow(true)}>
                  Buy Credits
                </button>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section>
        <Container>
          <div className="edit-profile-main mt-4">
            <h3 className="main-head-pro">Credits History</h3>
            <div className="upper-form-main">
              <div className="form-main-name-detal">
                <div className="upper-my-tournament-table">
                  <table className="w-100">
                    <tr>
                      <th>Transacton ID</th>
                      <th>Payment Method</th>
                      <th>Date</th>
                      <th>Time</th>
                      <th>Total Credits</th>
                      <th>Paid Amount</th>
                    </tr>
                    {creditPurchaseHistory && creditPurchaseHistory.length ? (
                      creditPurchaseHistory.map((element, key) => {
                        return (
                          <tr key={key}>
                            <td>#{element.transaction_id}</td>
                            <td>{element.payment_type} </td>
                            <td>{moment(element.created_at).format('DD-MM-YYYY')}</td>
                            <td>{moment(element.created_at).format('HH:mm')}</td>
                            <td>{element.total_credits}</td>
                            <td>£ {element.amount}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      {creditPurchaseHistory && creditPurchaseHistory.length > 0 ?
        <Pagination>
          <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage == 1} />
          <Pagination.Prev onClick={() => handlePageChange(Math.max(currentPage - 1, 1))} disabled={currentPage == 1} />
          {Array.from({ length: totalPages }).map((_, index) => (
            <Pagination.Item
              key={index + 1}
              active={index + 1 === currentPage}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next onClick={() => handlePageChange(Math.min(currentPage + 1, totalPages))} disabled={currentPage == totalPages} />
          <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage == totalPages} />
        </Pagination> : <></>}
      <Modal show={show} className="modal-logout" onHide={handleClose}>
        <Modal.Header className="border-0 pb-0" closeButton>

        </Modal.Header>
        <Modal.Body className="modal-padd">
          <div className="inner-ogout-btn">
            <h2>Select Credit Package </h2>
            <p className="text-damble-text">
              This transaction is non refundable, Are you sure you want to continue?
            </p>
          </div>
          <Form>
            <Form.Group>
              <div className="row">

                {creditPackage && creditPackage.length ? (
                  creditPackage.map((element) => {
                    return (
                      <div className="col-lg-6">
                        <Form.Check
                          type="radio"
                          label={
                            element.discounted_amount ? (
                              <>
                                GBP <span className="over-line">{element.amount}</span> {element.discounted_amount} Credits{' '}
                                {element.credit}
                              </>
                            ) : (
                              `GBP ${element.amount} Credits ${element.credit}`
                            )
                          }
                          name="creditPackage"
                          className="form-payment"
                          id={"creditPackage" + element.id}
                          onChange={() =>  setSelectedPackage(element)}
                        />
                      </div>
                    )})
                ):(
                  <p>No packages available</p>
                )}

              </div>
            </Form.Group>
          </Form>
          <div className="upper-payment-main">
            {selectedPackage && (
              <PayPalScriptProvider key={paypalKeyRef.current}  options={PayPalConfig}>
                <PayPalButtons createOrder={createOrder} onApprove={onApprove} onError={onError}/>
              </PayPalScriptProvider>
            )}
          </div>
        </Modal.Body>


        {/* <Button variant="primary" onClick={handleSaveChanges}>
          Buy Now
        </Button> */}


      </Modal>

    </>
  );
}

export default Credits;
