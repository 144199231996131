import React, { useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { Container } from 'react-bootstrap'
import axios from 'axios';
import { Link } from 'react-router-dom';
import rpfil from '../Assets/Images/Background.png'
import { BASE_URL, GET_USER_DETAILS ,IMAGE_BASE_URL} from "../API";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import userImage from '../assets/user.png'

function ProfileDetail({setIsOpen, userDetails}) {
    // useEffect(()=>{
    //     getProfileDetails();
    // },[])


  return (
    <>
    <section>

            <div className="edit-profile-main">
                <div className="d-flex justify-content-between">
                    <div className="img-all-detail d-flex">
                        <figure className='profile-img'>
                        <img src={userDetails?.user_details?.profile_picture ? IMAGE_BASE_URL + userDetails?.user_details?.profile_picture : userImage}  alt="" />
                        </figure>
                        <div className="detail-profile">
                            <h3>{userDetails?.name} </h3>
                            <p className='game-id'>Player ID: <span> {userDetails?.user_details?.player_id}</span></p>
                            {/* <p className='game-id'>PSN ID: <span> {userDetails?.user_details?.psn}</span></p>
                            <p className='game-id'>Activision ID: <span> {userDetails?.user_details?.activision}</span></p>
                            <p className='game-id'>EA ID: <span> {userDetails?.user_details?.ea}</span></p>
                            <p className='game-id'>UbiSoft ID: <span> {userDetails?.user_details?.ubisoft}</span></p>
                            <p className='game-id'>Xbox GamerTag ID: <span> {userDetails?.user_details?.xbox}</span></p> */}
                            <div className="email-ddet">
                                <i className="fa-solid fa-envelope"></i>
                                <Link to="">{userDetails?.email}</Link>
                            </div>
                            {
                                userDetails?.user_details?.address || userDetails?.user_details?.city ||  userDetails?.user_details?.state ?
                                <div className="location-ddet">
                                  <i className="fa-solid fa-location-dot"></i>
                                    <p>{userDetails?.user_details?.address}. {userDetails?.user_details?.city}, {userDetails?.user_details?.state}, {userDetails?.user_details?.country}  {userDetails?.user_details?.zip_code}</p>
                               </div>
                            :
                            <></>
                            }
                            
                        </div>
                    </div>
                    <div className="button-edit-prof" onClick={()=>setIsOpen(true)}>
                        <Link to="" className='edit-butt'><i className="fa-solid fa-pen-to-square"></i>Edit Profile</Link>
                    </div>
                </div>

            </div>

    </section>
</>
  )
}

export default ProfileDetail
