import React, { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import sigimg from "../assets/signup.png";
import Form from "react-bootstrap/Form";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import axiosClient from "../Services/axiosClient";
import { SIGN_UP, BASE_URL } from "../API";
import { toast } from "react-toastify";
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import logologin from '../Assets/Images/eu.png'
import Button from 'react-bootstrap/Button';
import Loader from "../components/Loader";
function Signup() {

  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate();
  const [isLoggedIn, setLoggedIn] = useState(() => {
    const token = localStorage.getItem('token');
    return !!token;
  });
  useEffect(() => {
    if (isLoggedIn) {
      navigate('/');
    }
  }, []);

  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    reset,
    register
  } = useForm();

  const onSubmit = async (data) => {
    console.log(data)
    setIsLoading(true);
    try {
      const response = await axiosClient.post(
        BASE_URL + SIGN_UP,
        JSON.stringify(data)
      );
      console.log('response::', response)
      if (response.data.status_code === 200 && response.data.status === true) {
        toast.success(response?.data?.message);
        setIsLoading(false);
        navigate('/login')
        reset();
      } else if (response.data.status_code === 400 && response.data.status === false) {
        toast.error(response?.data?.error[0]);
        setIsLoading(false);
      } else {
        toast.error(response?.data?.message);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error, "errorr");
      if (error.response && error.response.data) {
        const errorMessage =
          error.response.data.message ||
          "An error occurred";
        setIsLoading(false);
        toast.error(errorMessage);
      } else {
        toast.error("An error occurred");
        setIsLoading(false);
      }
      reset();
    }
  };

  const handleClickSignIn = () => {
    navigate("/login");
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <section className="login-section">
        <Container>
          <div className="inner-sec-login">
            <div className="logo-login text-center">
              <img src={logologin} alt="" />
            </div>
            <h2>Create your Account</h2>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group
                className="input-comn-styel-signup"
                controlId="formGroupFullName">
                <Form.Label className='label-clas-comon'>Username<span>*</span></Form.Label>
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Username is required",
                    minLength: {
                      value: 2,
                      message:
                        "Username must be at least 2 characters long",
                    },
                    maxLength: {
                      value: 50,
                      message: "Username cannot exceed 50 characters",
                    },
                  }}
                  render={({ field }) => (
                    <>
                      <Form.Control
                        {...field}
                        type="text"
                        className='input-comn-cls'
                        placeholder="Enter Username"
                      />
                      {errors?.name && (
                        <span className="text-danger">
                          {errors?.name.message}
                        </span>
                      )}
                    </>
                  )}
                />
              </Form.Group>
              <Form.Group
                className="input-comn-styel-signup"
                controlId="formGroupEmail">
                <Form.Label className='label-clas-comon'>Email<span>*</span></Form.Label>
                <Controller
                  name="email"

                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Email is required",
                    pattern: {
                      value: /^(?!.*\s)([A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}|[^\s@]+@[^\s@]+\.[^\s@]+)$/i,
                      message: "Invalid email address",
                    },
                  }}
                  render={({ field }) => (
                    <>
                      <Form.Control
                        {...field}
                        type="email"
                        className='input-comn-cls'
                        placeholder="Enter Your Email"
                      />
                      {errors.email && (
                        <span className="text-danger">
                          {errors.email.message}
                        </span>
                      )}
                    </>
                  )}
                />
              </Form.Group>
              <Form.Group
                className="input-comn-styel-signup"
                controlId="formGroupPassword">
                <Form.Label className='label-clas-comon'>Password<span>*</span></Form.Label>
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Password is required",
                    minLength: {
                      value: 8,
                      message:
                        "Password must be at least 8 characters long",
                    },
                    validate: {
                      hasUppercase: (value) =>
                        /[A-Z]/.test(value) ||
                        "Password must contain at least one uppercase letter",
                      hasLowercase: (value) =>
                        /[a-z]/.test(value) ||
                        "Password must contain at least one lowercase letter",
                      hasDigit: (value) =>
                        /\d/.test(value) ||
                        "Password must contain at least one digit",
                      hasSpecialChar: (value) =>
                        /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(
                          value
                        ) ||
                        "Password must contain at least one special character",
                    },
                  }}
                  render={({ field }) => (
                    <>
                      <Form.Control
                        {...field}
                        type="password"
                        className='input-comn-cls'
                        placeholder="Enter Your Password"
                      />
                      {errors.password && (
                        <span className="text-danger">
                          {errors.password.message}
                        </span>
                      )}
                    </>
                  )}
                />
              </Form.Group>
              <Form.Group
                className="input-comn-styel-signup"
                controlId="formGroupConfirmPassword">
                <Form.Label className='label-clas-comon'>Confirm Password<span>*</span></Form.Label>
                <Controller
                  name="password_confirmation"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Confirm Password is required",
                    validate: (value) =>
                      value === getValues("password") ||
                      "Passwords do not match",
                  }}
                  render={({ field }) => (
                    <>
                      <Form.Control
                        {...field}
                        type="password"
                        className='input-comn-cls'
                        placeholder="Enter Your Confirm Password"
                      />
                      {errors.password_confirmation && (
                        <span className="text-danger">
                          {errors.password_confirmation.message}
                        </span>
                      )}
                    </>
                  )}
                />
              </Form.Group>
              <Form.Group controlId="formGroupTermsAndConditions">
                <Controller
                  name="terms"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "You must to accept terms and conditions",
                  }}
                  render={({ field }) => (
                    <div className="d-flex main-flex-check">
                      <Form.Check
                        {...field}
                        type="checkbox"
                      />
                      <Form.Label className='chech-clas-comon'>I hereby declare that I accept the  <Link to="/terms&conditions"> Terms & Conditions</Link> & <Link to="/privacy-policy">Privacy Policy</Link></Form.Label>

                      {errors.terms && (
                        <span className="text-danger">
                          {errors.terms.message}
                        </span>
                      )}
                    </div>
                  )}
                />
              </Form.Group>
              <Button className='login-btn' type="submit">
                Create Account
              </Button>
            </Form>
            {/* <Form>
                        <Row>
                            <Col md={12}>
                                <Form.Group controlId="formGridEmail">
                                    <Form.Label className='label-clas-comon'>Name <span>*</span></Form.Label>
                                    <Form.Control type="text" className='input-comn-cls' placeholder="Enter your Name" />
                                </Form.Group>
                            </Col>
                            <Col md={12}>
                                <Form.Group controlId="formGridEmail">
                                    <Form.Label className='label-clas-comon'>Email <span>*</span></Form.Label>
                                    <Form.Control type="email" className='input-comn-cls' placeholder="Enter your Email" />
                                </Form.Group>
                            </Col>
                            <Col md={12}>
                                <Form.Group controlId="formGridPassword">
                                    <Form.Label className='label-clas-comon'>Password <span>*</span></Form.Label>
                                    <Form.Control type="password" className='input-comn-cls' placeholder="Enter your Password" />
                                </Form.Group>
                            </Col>
                            <Col md={12}>
                                <Form.Group controlId="formGridPassword">
                                <Form.Label className='label-clas-comon'>Confirm Password <span>*</span></Form.Label>
                                    <Form.Control type="password" className='input-comn-cls' placeholder="Enter your Confirm Password" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className="upper-main-forget-login d-flex">
                            <Form.Group id="formGridCheckbox" className='d-flex heard-chech'>
                                <Form.Check type="checkbox" />
                                <Form.Label className='chech-clas-comon'>I hereby declare that I accept the  <Link to="/terms&conditions"> Terms & Conditions</Link> & <Link to="/privacy-policy">Privacy Policy</Link></Form.Label>
                            </Form.Group>

                        </div>

                        <Button className='login-btn' type="submit">
                            Login
                        </Button>
                    </Form> */}
            <div className="not-register">
              <p>Already have an account?

                <Link to="/login"> Login</Link></p>
            </div>

          </div>
        </Container>
      </section>
    </>
  )
}
// return (
//   <>
//     <section className="signup-main-clas">
//       <Row className=" g-0">
//         {/* <Col md={6}>
//           <div className="inner-img-sign">
//             <figure>
//               <img src={sigimg} className="img-fluid" alt="" />
//             </figure>
//           </div>
//         </Col> */}
//         <Col md={6}>
//           <div className="upper-flex-signup">
//             <div className="inner-text-fields">
//               <div className="logo-signup">
//                 <p>Logo</p>
//               </div>
//               <div className="create-acc-sec">
//                 <h2>Create Account</h2>
//                 <div className="upper-div-sign-up">
//                   <Form onSubmit={handleSubmit(onSubmit)}>
//                     <Form.Group
//                       className="input-comn-styel-signup"
//                       controlId="formGroupFullName">
//                       <Form.Label>Full Name</Form.Label>
//                       <Controller
//                         name="name"
//                         control={control}
//                         defaultValue=""
//                         rules={{
//                           required: "Full Name is required",
//                           minLength: {
//                             value: 2,
//                             message:
//                               "Full Name must be at least 2 characters long",
//                           },
//                           maxLength: {
//                             value: 50,
//                             message: "Full Name cannot exceed 50 characters",
//                           },
//                         }}
//                         render={({ field }) => (
//                           <>
//                             <Form.Control
//                               {...field}
//                               type="text"
//                               placeholder="Enter your Full Name"
//                             />
//                             {errors?.name && (
//                               <span className="text-danger">
//                                 {errors?.name.message}
//                               </span>
//                             )}
//                           </>
//                         )}
//                       />
//                     </Form.Group>
//                     <Form.Group
//                       className="input-comn-styel-signup"
//                       controlId="formGroupEmail">
//                       <Form.Label>Email Address</Form.Label>
//                       <Controller
//                         name="email"
//                         control={control}
//                         defaultValue=""
//                         rules={{
//                           required: "Email is required",
//                           pattern: {
//                             value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
//                             message: "Invalid email address",
//                           },
//                         }}
//                         render={({ field }) => (
//                           <>
//                             <Form.Control
//                               {...field}
//                               type="email"
//                               placeholder="Enter your Email Address"
//                             />
//                             {errors.email && (
//                               <span className="text-danger">
//                                 {errors.email.message}
//                               </span>
//                             )}
//                           </>
//                         )}
//                       />
//                     </Form.Group>
//                     <Form.Group
//                       className="input-comn-styel-signup"
//                       controlId="formGroupPassword">
//                       <Form.Label>Create Password</Form.Label>
//                       <Controller
//                         name="password"
//                         control={control}
//                         defaultValue=""
//                         rules={{
//                           required: "Password is required",
//                           minLength: {
//                             value: 8,
//                             message:
//                               "Password must be at least 8 characters long",
//                           },
//                           validate: {
//                             hasUppercase: (value) =>
//                               /[A-Z]/.test(value) ||
//                               "Password must contain at least one uppercase letter",
//                             hasLowercase: (value) =>
//                               /[a-z]/.test(value) ||
//                               "Password must contain at least one lowercase letter",
//                             hasDigit: (value) =>
//                               /\d/.test(value) ||
//                               "Password must contain at least one digit",
//                             hasSpecialChar: (value) =>
//                               /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(
//                                 value
//                               ) ||
//                               "Password must contain at least one special character",
//                           },
//                         }}
//                         render={({ field }) => (
//                           <>
//                             <Form.Control
//                               {...field}
//                               type="password"
//                               placeholder="Enter Create Password"
//                             />
//                             {errors.password && (
//                               <span className="text-danger">
//                                 {errors.password.message}
//                               </span>
//                             )}
//                           </>
//                         )}
//                       />
//                     </Form.Group>
//                     <Form.Group
//                       className="input-comn-styel-signup"
//                       controlId="formGroupConfirmPassword">
//                       <Form.Label>Confirm Password</Form.Label>
//                       <Controller
//                         name="password_confirmation"
//                         control={control}
//                         defaultValue=""
//                         rules={{
//                           required: "Confirm Password is required",
//                           validate: (value) =>
//                             value === getValues("password") ||
//                             "Passwords do not match",
//                         }}
//                         render={({ field }) => (
//                           <>
//                             <Form.Control
//                               {...field}
//                               type="password"
//                               placeholder="Enter Confirm Password"
//                             />
//                             {errors.password_confirmation && (
//                               <span className="text-danger">
//                                 {errors.password_confirmation.message}
//                               </span>
//                             )}
//                           </>
//                         )}
//                       />
//                     </Form.Group>
//                     <Form.Group controlId="formGroupTermsAndConditions">
//                     <Controller
//                         name="terms"
//                         control={control}
//                         defaultValue=""
//                         rules={{
//                           required: "You must to accept terms and conditions",
//                         }}
//                         render={({ field }) => (
//                           <>
//                             <Form.Check
//                               {...field}
//                               type="checkbox"
//                               label="I agree to the terms and conditions"
//                             />
//                             {errors.terms && (
//                               <span className="text-danger">
//                                 {errors.terms.message}
//                               </span>
//                             )}
//                           </>
//                         )}
//                         />
//                   </Form.Group>
//                     <button className="get-start-btn-comn mt-1" type="submit">
//                       Register Now
//                     </button>
//                   </Form>
//                 </div>
//                 <p className="alredy-have-acc">
//                   Already have an account?{" "}
//                   <span onClick={handleClickSignIn}>Login</span>
//                 </p>
//               </div>
//             </div>
//           </div>
//         </Col>
//       </Row>
//     </section>
//   </>
// );


export default Signup;
