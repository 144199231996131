import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { Pagination } from "react-bootstrap/Pagination";
import { useNavigate } from "react-router-dom";

function UserInviteModel({ invitedUser, setShowModel, inviteParams }) {
  const token = localStorage.getItem('token')
  const [show, setShow] = useState(invitedUser);
  const navigate = useNavigate();

  useEffect(() => {
    console.log(inviteParams)
  }, [])

  const handleClose = () => setShowModel(false);
  const handleShow = () => setShowModel(true);
  const handleAccept = () => {
    if (token) {
      localStorage.setItem("inviteData", JSON.stringify(inviteParams));
      localStorage.removeItem('user')
      localStorage.removeItem('token')
      const baseUrl = window.location.origin;
      const cleanUrl = `${baseUrl}#/signup`;
      window.location.href = cleanUrl;
      toast.success("Request has been sent, please register to proceed");
    }
    else {
      localStorage.setItem("inviteData", JSON.stringify(inviteParams));
      const baseUrl = window.location.origin;
      const cleanUrl = `${baseUrl}#/signup`;
      window.location.href = cleanUrl;
      toast.success("Request has been sent, please register to proceed");
    }
  };
  return (
    <Modal show={show} onHide={handleClose}>
      {/* <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header> */}
      <Modal.Body className="modal-padd">
        <div className="inner-ogout-btn">
          <h2>TOURNAMENT INVITATION</h2>

          <p className="text-damble-text">Do you want to join "{inviteParams.team_slug}" ?</p>
        </div>

        <div className="upper-btn-llogout-main d-flex">
          <a className="edit-butt" onClick={() => handleAccept()}>
            Yes
          </a>
          <button onClick={() => handleClose()} className="login-head">
            No
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default UserInviteModel;
