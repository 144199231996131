import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Container, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import TeammanageView from "./TeammanageView";
import EditTeam from "./EditTeam";
import { BASE_URL, DELETE_TEAM } from "../API";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Pagination from 'react-bootstrap/Pagination';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
const ITEMS_PER_PAGE = 4;
function TeamManage({ setIsOpen, userDetails, teamList, getMyTeams }) {
  const navigate = useNavigate()
  const [showDetails, setShowDetails] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState();
  const [selectedTeamId, setSelectedTeamId] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pageContent, setPageContent] = useState('')
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  useEffect(() => {
    handlePageContent();
  }, [currentPage]);

  const openShowDetailsSection = (element) => {
    setShowDetails(true);
    setSelectedTeam(element);
  };

  const deleteTeam = (element) => {
    handleShow(true);
    setSelectedTeamId(element.id);
  };

  const deleteTeamById = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const response = await axios.post(BASE_URL + DELETE_TEAM, {
        id: selectedTeamId
      }, {
        headers: headers
      });

      if (response.data.status_code === 200 && response.data.status === true) {
        toast.success(response.data.message);
        handleClose();
        getMyTeams();
        setIsLoading(false);
        navigate('/myprofile', { state: { menu: 1 } })
      } else if (
        response.data.status_code === 400 &&
        response.data.status === false
      ) {
        toast.error(response?.data?.error[0]);
        setIsLoading(false);
      } else if (
        response.data.status_code === 403 &&
        response.data.status === false
      ) {
        setIsLoading(false);
      } else {
        toast.error(response.data.message);
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message || "An error occurred";
        toast.error(errorMessage);
        setIsLoading(false);
      } else {
        toast.error("An error occurred");
        setIsLoading(false);
      }
    }

  };

  const handlePageContent = () => {
    const totalCount = teamList.length;
    setTotalPages(Math.ceil(totalCount / ITEMS_PER_PAGE));
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const currentItems = teamList.slice(startIndex, startIndex + ITEMS_PER_PAGE);
    setPageContent(currentItems);
    setIsLoading(false);
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      {!showDetails && (
        <section>
          <div className="edit-profile-main">
            <div className="upper-btn-create d-flex">
              <h3 className="main-head-pro mb-0">Team Management</h3>
              <button className="edit-butt" onClick={() => setIsOpen(true)}>
                Create Team
              </button>
            </div>
            <div className="upper-form-main team-manage-main">
              <div className="form-main-name-detal vip-sub row">
                {pageContent && pageContent.length ? (
                  pageContent.map((element) => {
                    return (
                      <div key={element.id} className="col-md-6">
                        <div className="inner-back-color">
                          <h3>{element.team_name}</h3>
                          <div className="players-main">
                            <p>{element.total_players} Players</p>
                          </div>
                          <p className="text-main-card">
                            {element.description}
                          </p>
                          <div className="buttons-main-w-border">
                            <button
                              className="edit-butt justify-content-center"
                              onClick={() => openShowDetailsSection(element)}
                            >
                              View Details
                            </button>
                            {element.status === 'lock' ? (
                              <>
                                <div className="upper-div-lock">
                                  <div className="upper-back-color">
                                    <FontAwesomeIcon icon={faLock} />
                                  </div>
                                  <button className="edit-butt justify-content-center border-bg text-center" disabled>
                                    Delete Team
                                  </button>
                                </div>
                              </>

                            ) : (
                              <button className="edit-butt justify-content-center border-bg text-center"
                                onClick={() => deleteTeam(element)}>Delete Team
                              </button>
                            )
                            }
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p>No Teams</p>
                )}
              </div>
            </div>
          </div>
          {teamList && teamList.length ?
            <Pagination>
              <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage == 1} />
              <Pagination.Prev onClick={() => handlePageChange(Math.max(currentPage - 1, 1))} disabled={currentPage == 1} />
              {Array.from({ length: totalPages }).map((_, index) => (
                <Pagination.Item
                  key={index + 1}
                  active={index + 1 === currentPage}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              ))}
              <Pagination.Next onClick={() => handlePageChange(Math.min(currentPage + 1, totalPages))} disabled={currentPage == totalPages} />
              <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage == totalPages} />
            </Pagination> : <></>}
        </section>
      )}
      {showDetails && !isEdit && (
        <TeammanageView setIsEdit={setIsEdit} selectedTeam={selectedTeam} />
      )}

      {isEdit && (
        <EditTeam selectedTeam={selectedTeam} getMyTeams={getMyTeams} />
      )}

      <Modal className="modal-logout" show={show} onHide={handleClose}>
        {/* <Modal.Header className="border-0" closeButton>

                            </Modal.Header> */}
        <Modal.Body className="modal-padd">
          <div className="inner-ogout-btn">
            <h2>Are You sure you want to delete team ?</h2>
            <p className="text-damble-text">
              The action you are going to perform is irreversible please
              Confirm! Are you sure that you want to delete team?
            </p>
          </div>
          <div className="upper-btn-llogout-main d-flex">
            <Link onClick={() => handleClose()} className="login-head">
              Close
            </Link>
            <a className="edit-butt" onClick={() => deleteTeamById()}>
              Delete Team
            </a>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default TeamManage;
