import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import sigimg from "../assets/signup.png";
import Form from "react-bootstrap/Form";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import axiosClient from "../Services/axiosClient";
import { BASE_URL, RESET_PASSWORD } from "../API";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function Createnewpassword() {
  const navigate = useNavigate();
  const [isLoggedIn, setLoggedIn] = useState(() => {
    const token = localStorage.getItem('token');
    return !!token;
  });
  useEffect(()=>{
    if(isLoggedIn){
      navigate('/');
    }
  },[]);
  
  let email = localStorage.getItem("email_reset");

  const {
    handleSubmit,
    reset,
    formState: { errors },
    control,
    watch,
  } = useForm();


  const newPassword = watch("new_password", "");

  const onSubmit = async (data) => {
    let requestData = {
      ...data,
      email: email,
    };
    try {
      const response = await axiosClient.post(
        BASE_URL + RESET_PASSWORD,
        requestData
      );
      if (response.data.code === 200) {
        toast.success(response.data.message);
        navigate("/login");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error,"err")
      if (error.response && error.response.data) {
        const errorMessage =
          error.response.data.message ||
          "An error occurred";
        toast.error(errorMessage);
      } else {
        toast.error("An error occurred");
      }
      reset();
    }
  };

  return (
    <>
      <section className="signup-main-clas">
        <Row className=" g-0">
          <Col md={6}>
            <div className="inner-img-sign login-page-clas">
              <figure>
                <img src={sigimg} className="img-fluid" alt="" />
              </figure>
            </div>
          </Col>
          <Col md={6}>
            <div className="upper-flex-signup reset-password">
              <div className="inner-text-fields">
                <div className="create-acc-sec">
                  <h2>Create New Password </h2>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group
                      className="input-comn-styel-signup"
                      controlId="formGroupPassword"
                    >
                      <Form.Label>New Password</Form.Label>
                      <Controller
                        name="new_password"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <Form.Control
                            type="password"
                            placeholder="Enter New Password"
                            {...field} // Bind input value and events
                          />
                        )}
                      />
                    </Form.Group>
                    <Form.Group
                      className="input-comn-styel-signup"
                      controlId="formGroupConfirmPassword"
                    >
                      <Form.Label>Confirm New Password</Form.Label>
                      <Controller
                        name="confirm_password"
                        control={control}
                        defaultValue=""
                        rules={{
                          validate: (value) =>
                            value === newPassword ||
                            "Passwords do not match", // Custom validation function
                        }}
                        render={({ field }) => (
                          <Form.Control
                            type="password"
                            placeholder="Enter Confirm New Password"
                            {...field} // Bind input value and events
                          />
                        )}
                      />
                      {errors.confirm_password && (
                        <span className="text-danger">
                          {errors.confirm_password.message}
                        </span>
                      )}
                    </Form.Group>
                    <button className="get-start-btn-comn mt-1" type="submit">
                      Reset Password
                    </button>
                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
}

export default Createnewpassword;
