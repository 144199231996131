import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { FILE_UPLOAD, CREATE_TEAM, BASE_URL, IMAGE_BASE_URL } from "../API";
import Loader from "./Loader";
import axios from "axios"; // Make sure axios is imported
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function CreateTeam({ userDetails, getMyTeams, setIsOpen }) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
    reset,
    register,
  } = useForm();

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const response = await axios.post(
        BASE_URL + CREATE_TEAM,
        JSON.stringify(data),
        { headers: headers }
      );
      if (response.data.status_code === 200 && response.data.status === true) {
        setIsLoading(false);
        toast.success(response.data.message);
        getMyTeams();
        setIsOpen(false);
        navigate('/myprofile', { state: { menu: 1 } })
      } else if (
        response.data.status_code === 400 &&
        response.data.status === false
      ) {
        setIsLoading(false);
        toast.error(response?.data?.error[0]);
      } else if (
        response.data.status_code === 403 &&
        response.data.status === false) {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        navigate('/login');
      } else {
        // console.log('response.data::',response)
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setIsLoading(false);
        const errorMessage = error.response.data.error[0] || "An error occurred";
        toast.error(errorMessage);
      } else {
        setIsLoading(false);
        toast.error("An error occurred");
      }
    } finally {
      reset()
      setValue('total_players', '')
    }
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <section>
        <div className="edit-profile-main">
          <div className="upper-btn-create d-flex">
            <h3 className="main-head-pro">Create Team</h3>
          </div>

          <div className="form-main-name-detal">
            <Form className="form-clas-login" onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col md={6} className="comn-inpt-main">
                  <Form.Group className="" controlId="formGroupTeamName">
                    <Form.Label className="label-clas-comon">
                      Team Name<span>*</span>
                    </Form.Label>
                    <Controller
                      name="team_name"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Team name is required",
                        minLength: {
                          value: 2,
                          message:
                            "Team name must be at least 2 characters long",
                        },
                        maxLength: {
                          value: 50,
                          message: "Team name cannot exceed 50 characters",
                        },
                        pattern: {
                          value: /^\S(.*\S)?$/, // Regular expression to disallow leading and trailing white spaces
                          message: "White spaces are not allowed",
                        },
                      }}
                      render={({ field }) => (
                        <>
                          <Form.Control
                            {...field}
                            type="text"
                            className="input-comn-cls"
                            placeholder="Enter Your Team Name"
                          />
                          {errors?.team_name && (
                            <span className="text-danger">
                              {errors?.team_name.message}
                            </span>
                          )}
                        </>
                      )}
                    />
                  </Form.Group>
                </Col>
                <Col md={6} className="comn-inpt-main">
                  <Form.Group
                    className=""
                    controlId="formGroupFullName"
                  >
                    <Form.Label className="label-clas-comon">
                      Total Player<span>*</span>
                    </Form.Label>
                    <Controller
                      name="total_players"
                      control={control}
                      rules={{
                        required: "This field is required",
                        minLength: {
                          value: 1,
                          message:
                            "Total player must be at least 1 number long",
                        },
                        maxLength: {
                          value: 50,
                          message: "Total player cannot exceed 50 number",
                        },
                        pattern: {
                          value: /^\S(.*\S)?$/, // Regular expression to disallow leading and trailing white spaces
                          message: "White spaces are not allowed",
                        },
                      }}
                      render={({ field }) => (
                        <>
                          <Form.Select aria-label="Default select example"   {...field}>
                            <option selected disabled value=''>Select Number Of Players</option>
                            <option value="1">1v1</option>
                            <option value="2">2v2</option>
                            <option value="3">3v3</option>
                            <option value="4">4v4</option>

                          </Form.Select>
                          {errors?.total_players && (
                            <span className="text-danger">
                              {errors?.total_players.message}
                            </span>
                          )}
                        </>
                      )}
                    />

                  </Form.Group>

                </Col>


                {/* 
                <Col md={6} className="comn-inpt-main">
                  <Form.Group className="" controlId="formGroupTotalPlayer">
                    <Form.Label className="label-clas-comon">
                      Total Player<span>*</span>
                    </Form.Label>
                    <Controller
                      name="total_players"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Total player is required",
                        minLength: {
                          value: 1,
                          message:
                            "Total player must be at least 1 number long",
                        },
                        maxLength: {
                          value: 50,
                          message: "Total player cannot exceed 50 number",
                        },
                        pattern: {
                          value: /^\S(.*\S)?$/, // Regular expression to disallow leading and trailing white spaces
                          message: "White spaces are not allowed",
                        },
                      }}
                      render={({ field }) => (
                        <>
                          <Form.Control
                            {...field}
                            type="number"
                            className="input-comn-cls"
                            placeholder="Enter Your Total Player"
                          />
                          {errors?.total_players && (
                            <span className="text-danger">
                              {errors?.total_players.message}
                            </span>
                          )}
                        </>
                      )}
                    />
                  </Form.Group>
                </Col> */}

                {/* <Col md={12} className="comn-inpt-main">
                <Form.Group className="" controlId="formGroupDescription">
                    <Form.Label className="label-clas-comon">
                    Description<span>*</span>
                    </Form.Label>
                    <Controller
                      name="description"
                      control={control}
                      defaultValue=""
                        rules={{
                            required: "Description is required",
                            minLength: {
                              value: 50,
                              message:
                                "Description must be at least 50 characters long",
                            },
                            maxLength: {
                              value: 1000,
                              message: "Description cannot exceed 1000 characters",
                            },
                            pattern: {
                              value: /^\S(.*\S)?$/, // Regular expression to disallow leading and trailing white spaces
                              message: "White spaces are not allowed"
                            }
                          }}
                      render={({ field }) => (
                        <>
                          <Form.Control
                            {...field}
                            as="textarea"
                            className="input-comn-cls"
                            placeholder="Enter Your Team Description"
                          />
                          {errors?.description && (
                            <span className="text-danger">
                              {errors?.description.message}
                            </span>
                          )}
                        </>
                      )}
                    />
                  </Form.Group>
                </Col> */}
              </Row>
              <div className="upper-btn-login-page text-start mt-2">
                <button className="edit-butt" disabled={isLoading}>
                  {isLoading ? 'Submitting...' : 'Submit'}
                </button>
              </div>
            </Form>
          </div>
        </div>
      </section>
    </>
  );
}

export default CreateTeam;
