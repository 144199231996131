import React, { useEffect, useState } from "react";
import logologin from '../Assets/Images/eu.png'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useForm, Controller } from "react-hook-form";
import { FORGET_PASSWORD, BASE_URL } from "../API";
import axiosClient from "../Services/axiosClient";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Container } from 'react-bootstrap'
import Loader from "../components/Loader";

function ForgotPassword() {
    const [isLoading, setIsLoading] = useState(false)
    const [isLoggedIn, setLoggedIn] = useState(() => {
        const token = localStorage.getItem('token');
        return !!token;
      });
      useEffect(()=>{
        if(isLoggedIn){
          navigate('/');
        }
      },[]);
    const {
        handleSubmit,
        formState: { errors },
        control,
        getValues,
        reset,
        register
    } = useForm();
    const navigate = useNavigate();

    const onSubmit = async (data) => {
        setIsLoading(true)
        try {
            const response = await axiosClient.post(
                BASE_URL + FORGET_PASSWORD,
                JSON.stringify(data)
            );

            if (response.data.status_code === 200 && response.data.status === true) {
                console.log('response::',response)
                setIsLoading(false);
                toast.success(response.data.message)
                // localStorage.setItem("token", response?.data?.token)
                // localStorage.setItem("user", JSON.stringify(response?.data?.user))
                navigate("/");
            } else {
                setIsLoading(false);
                toast.error(response?.data?.message);
            }
        } catch (error) {
            if (error.response && error.response.data) {
                setIsLoading(false);
                const errorMessage =
                    error.response.data.message ||
                    "An error occurred";
                toast.error(errorMessage);

            } else {
                setIsLoading(false);
                toast.error("An error occurred");
            }
            reset();
        }
    };

    return (
        <>
                <Loader isLoading={isLoading}/>

            <section className="login-section">
                <Container>
                    <div className="inner-sec-login">
                        <div className="logo-login text-center">
                            <img src={logologin} alt="" />
                        </div>
                        <h2>Lost your password?</h2>
                        <p className='forget-pass-text'>Please enter your email address. You will receive a
                            link to create a new password via email.</p>
                        <Form onSubmit={handleSubmit(onSubmit)}>


                            <Form.Group
                                className="input-comn-styel-signup"
                                controlId="formGroupEmail">
                                <Form.Label className='label-clas-comon'>Email<span>*</span></Form.Label>
                                <Controller
                                    name="email"

                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: "Email is required",
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "Invalid email address",
                                        },
                                    }}
                                    render={({ field }) => (
                                        <>
                                            <Form.Control
                                                {...field}
                                                type="email"
                                                className='input-comn-cls'
                                                placeholder="Enter your Email Address"
                                            />
                                            {errors.email && (
                                                <span className="text-danger">
                                                    {errors.email.message}
                                                </span>
                                            )}
                                        </>
                                    )}
                                />
                            </Form.Group>
                            <Button className='login-btn' type="submit">
                                Reset Password
                            </Button>
                        </Form>
                        {/* <Form>
                    <Row>
                        <Col md={12}>
                            <Form.Group controlId="formGridEmail">
                                <Form.Label className='label-clas-comon'>Email Address <span>*</span></Form.Label>
                                <Form.Control type="email" className='input-comn-cls' placeholder="Enter your Email Address" />
                            </Form.Group>
                        </Col>

                    </Row>


                    <Button className='login-btn' type="submit">
                        Reset Password
                    </Button>
                </Form> */}


                    </div>
                </Container>
            </section>


        </>
    )
}

export default ForgotPassword
