import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { SIGN_IN, BASE_URL,INVITED_PLAYER } from "../API";
import axiosClient from "../Services/axiosClient";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Container } from 'react-bootstrap'
import logologin from '../Assets/Images/eu.png'
import Button from 'react-bootstrap/Button';
import Loader from "../components/Loader";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

function Login() {

  const [rememberMe, setRememberMe ] = useState(false);
  const [remindData, setRemindData] = useState(JSON.parse(localStorage.getItem('remind_data')))
  const [isLoading, setIsLoading] = useState(false)
  const [inviteData, setIsnviteData] = useState(JSON.parse(localStorage.getItem('inviteData')))
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [isLoggedIn, setLoggedIn] = useState(() => {
    const token = localStorage.getItem('token');
    return !!token;
  });
  

  const onRemindMeChange = (event) =>{
    setRememberMe(event.target.checked);
  }
  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
    reset,
    register
  } = useForm();

  useEffect(()=>{
    if(remindData){
      setRememberMe(true)
        setValue('email',remindData.email);
        setValue('password', remindData.password);
    }
    if(isLoggedIn){navigate('/');}
  },[]);

  const onSubmit = async (data) => {
    setIsLoading(true)
    try {
      if(rememberMe){
        localStorage.setItem('remind_data', JSON.stringify(data))
      }else{
        localStorage.removeItem('remind_data')
      }
      const response = await axiosClient.post(
        BASE_URL + SIGN_IN,
        JSON.stringify(data)
      );

      if (response.data.status_code === 200 && response.data.status === true) {
        console.log('response?.data?.user::',response?.data)
        toast.success(response.data.message)
        localStorage.setItem("token", response?.data?.token)
        localStorage.setItem("user", JSON.stringify(response?.data?.user));
        console.log('inviteData::',inviteData)
        if(inviteData){
          sendRequetTojoinInTeam(response?.data?.user);
        }
        setIsLoading(false);
        navigate("/");
      } else if(response.data.status_code === 400 && response.data.status === false){
        setIsLoading(false);
        toast.error(response?.data?.error[0]);
      } else {
        // console.log('response.data::',response)
        setIsLoading(false);
        toast.error(response.data.message)
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setIsLoading(false);
        const errorMessage =
          error.response.data.message ||
          "An error occurred";
        toast.error(errorMessage);
      } else {
        setIsLoading(false);
        toast.error("An error occurred");
      }
      reset();
    }
  };

  const handleForgotPassword = () => {
    navigate("/resetpassword");
  };

  const sendRequetTojoinInTeam = async (current_user) => {
    const inviteData=JSON.parse(localStorage.getItem('inviteData'))
      if(inviteData && inviteData.user_id === current_user.id){
       toast.success("You are the captain on this team.");
       return false;
      }

    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const response = await axios.post(
        BASE_URL + INVITED_PLAYER,
        JSON.stringify({user_id:current_user.id, team_id:inviteData.team_id,team_slug:inviteData.team_slug,team_token:inviteData.team_token}),
        { headers: headers }
      );

      if (response.data.status_code === 200 && response.data.status === true) {
        console.log('response.data::',response)
        setIsLoading(false);
        toast.success(response.data.message);
        localStorage.removeItem('inviteData');
      } else if (
        response.data.status_code === 400 &&
        response.data.status === false
      ) {
        setIsLoading(false);
        toast.error(response?.data?.error[0]);
      }  else if(    
        response.data.status_code === 403 &&
        response.data.status === false){
          localStorage.removeItem('user');
          localStorage.removeItem('token');
          navigate('/login');
      }else {
        // console.log('response.data::',response)
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setIsLoading(false);
        const errorMessage = error.response.data.message || "An error occurred";
        toast.error(errorMessage);
      } else {
        setIsLoading(false);
        toast.error("An error occurred");
      }
      reset();
    }
  };
  return (
    <>
        <Loader isLoading={isLoading}/>
      <section className="login-section">
        <Container>
          <div className="inner-sec-login">
            <div className="logo-login text-center">
              <img src={logologin} alt="" />
            </div>
            <h2>Login to your Account</h2>
            <Form onSubmit={handleSubmit(onSubmit)}>


              <Form.Group
                className="input-comn-styel-signup"
                controlId="formGroupEmail">
                <Form.Label className='label-clas-comon'>Email Address<span>*</span></Form.Label>
                <Controller
                  name="email"

                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  }}
                  render={({ field }) => (
                    <>
                      <Form.Control
                        {...field}
                        type="email"
                        className='input-comn-cls'
                        placeholder="Enter Your Email Address"
                      />
                      {errors.email && (
                        <span className="text-danger">
                          {errors.email.message}
                        </span>
                      )}
                    </>
                  )}
                />
              </Form.Group>

              <Form.Group
                className="input-comn-styel-signup"
                controlId="formGroupPassword">
                <Form.Label className='label-clas-comon'>Password<span>*</span></Form.Label>
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Password is required",
                    minLength: {
                      value: 8,
                      message:
                        "Password must be at least 8 characters long",
                    },
                    validate: {
                      hasUppercase: (value) =>
                        /[A-Z]/.test(value) ||
                        "Password must contain at least one uppercase letter",
                      hasLowercase: (value) =>
                        /[a-z]/.test(value) ||
                        "Password must contain at least one lowercase letter",
                      hasDigit: (value) =>
                        /\d/.test(value) ||
                        "Password must contain at least one digit",
                      hasSpecialChar: (value) =>
                        /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(
                          value
                        ) ||
                        "Password must contain at least one special character",
                    },
                  }}
                  render={({ field }) => (
                    <>
                    <div className="upper-input">
                      <Form.Control
                        {...field}
                        type={showPassword ? 'text' : 'password'}
                        className='input-comn-cls'
                        placeholder="Enter Your Password"
                      />
                      <button
                          type="button"
                          className="password-toggle"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                        </button>
                        </div>
                      {errors.password && (
                        <span className="text-danger">
                          {errors.password.message}
                        </span>
                      )}
                    </>
                  )}
                />
              </Form.Group>
              <div className="upper-main-forget-login d-flex">
                  <Form.Group id="formGridCheckbox">
                      <Form.Check type="checkbox"className="" label="Remember Me" onChange={(event)=>onRemindMeChange(event)} checked={rememberMe}/>
                  </Form.Group>
                  <Link to="/forgotpassword" style={{color:"blue"}}>Forgot Password?</Link>
              </div>
              <Button className='login-btn' type="submit">
                Login
              </Button>
            </Form>
            <div className="not-register">
              <p>Not Registered Yet?
                <Link to="/signup"> Create an account</Link></p>
            </div>

          </div>
        </Container>
      </section>


    </>
  );
}

export default Login;
