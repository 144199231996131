import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios'
import Form from 'react-bootstrap/Form';
import { Container, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { update } from 'firebase/database';
import { BASE_URL, GENERATE_LINK, PLAYER_LIST, REMOVE_TEAM_PLAYER } from "../API";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Invite_StatusModal from './Invite_StatusModal';
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

function TeammanageView({ setIsEdit, selectedTeam }) {
    const [link, setLink] = useState('');
    const [playerLobbyList, setPlayerLobbyList] = useState([]);
    const [isShowModal, setIsShowModal] = useState(false);
    const [selectedInvitationRequest, setSelectInvitationRequest] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const [showRemoveModal, setShowRemove] = useState(false);
    const [modalId, setModalId] = useState(null);
    const [isLocked, setIsLocked] = useState(false)
    const navigate = useNavigate();


    useEffect(() => {
        getSharableLink();
        getPlayerLobby();
        if (selectedTeam.status === 'lock') { setIsLocked(true); }
    }, []);

    const getSharableLink = async () => {
        try {
            const token = localStorage.getItem("token");
            const headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            };

            const response = await axios.get(BASE_URL + GENERATE_LINK + "?id=" + selectedTeam.id, {
                headers: headers,
            });
            if (response.data.status_code === 200 && response.data.status === true) {
                setLink(response.data.url)

            } else if (
                response.data.status_code === 400 &&
                response.data.status === false
            ) {
                toast.error(response?.data?.error[0]);
            } else if (
                response.data.status_code === 403 &&
                response.data.status === false
            ) {
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                navigate("/login");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            if (error.response && error.response.data) {
                const errorMessage = error.response.data.message || "An error occurred";
                toast.error(errorMessage);
            } else {
                toast.error("An error occurred");
            }
        }
    };


    // const copyLink = async () => {
    //     const link = document.getElementById('linkToCopy').href;
    //     console.log('dd',link)
    //     try {
    //         await navigator.clipboard.writeText(link);
    //         toast.success('Link copied to clipboard!');

    //     } catch (err) {
    //         toast.error('Failed to copy: ', err);
    //     }
    // }

    const copyLink = async (link) => {
        if (!link) {
            return false;
        }
        try {
            const el = document.createElement('textarea');
            el.value = link;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            toast.success('Link copied to clipboard!');
        } catch (err) {
            toast.error('Failed to copy: ' + err);
            console.error('Failed to copy:', err);
        }
    };

    const getPlayerLobby = async () => {
        const token = localStorage.getItem('token')
        try {
            const headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            };
            const response = await axios.get(BASE_URL + PLAYER_LIST + '?team_id=' + selectedTeam.id, {
                headers: headers,
            });
            console.log(response.data.players)
            if (response.data.status_code === 200 && response.data.status === true) {
                setPlayerLobbyList(response.data.players)
            } else if (
                response.data.status_code === 400 &&
                response.data.status === false
            ) {
                toast.error(response?.data?.error[0]);
            } else if (
                response.data.status_code === 403 &&
                response.data.status === false
            ) {
                localStorage.removeItem("user");
                localStorage.removeItem("token");
            } else {
                // console.log('response.data::',response)
                toast.error(response.data.message);
            }
        } catch (error) {
            if (error.response && error.response.data) {
                const errorMessage = error.response.data.message || "An error occurred";
                toast.error(errorMessage);
            } else {
                toast.error("An error occurred");;
            }
        }
    }

    const removePlayer = async (id) => {
        setIsLoading(true);
        try {
            const token = localStorage.getItem("token");
            const headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            };

            const response = await axios.post(BASE_URL + REMOVE_TEAM_PLAYER, {
                team_id: selectedTeam.id,
                player_id: id,
            }, {
                headers: headers
            });

            if (response.data.status_code === 200 && response.data.status === true) {
                toast.success(response.data.message);
                getPlayerLobby();
            } else if (
                response.data.status_code === 400 &&
                response.data.status === false
            ) {
                toast.error(response?.data?.error[0]);
                setIsLoading(false);
            } else if (
                response.data.status_code === 403 &&
                response.data.status === false
            ) {
                setIsLoading(false);
            } else {
                toast.error(response.data.message);
                setIsLoading(false);
            }
        } catch (error) {
            if (error.response && error.response.data) {
                const errorMessage = error.response.data.message || "An error occurred";
                toast.error(errorMessage);
                setIsLoading(false);
            } else {
                toast.error("An error occurred");
                setIsLoading(false);
            }
        }
    }
    const updatePlayerStatus = (player) => { setSelectInvitationRequest(player) }

    const closeModel = () => { setIsShowModal(false) }

    const openModel = (player) => {
        updatePlayerStatus(player)
        setIsShowModal(true)
    }

    const removeModal = (id) => {
        setModalId(id);
        setShowRemove(true);
    }

    return (
        <>
            {isShowModal && <Invite_StatusModal isShowModal={isShowModal} closeModel={closeModel} user_id={selectedInvitationRequest.id} team_id={selectedTeam.id} />}
            <section>
                <div className="edit-profile-main">
                    <div className="upper-btn-create d-flex">
                        <h3 className='main-head-pro mb-0'>Team Management</h3>
                        {isLocked ?
                            (<>
                                <div className="upper-div-lock">
                                    <div className="upper-back-color">
                                        <FontAwesomeIcon icon={faLock} />
                                    </div>
                                    <button className="edit-butt justify-content-center border-bg text-center" disabled>Edit Team</button>
                                </div>
                            </>
                            ) :
                            (<button className="edit-butt" onClick={() => setIsEdit(true)}>Edit Team</button>)}
                    </div>
                    <div className="upper-form-main">
                        <div className="form-main-name-detal team-detail-main">
                            <div className="team-name-all">
                                <div className="team-name">
                                    <h6>Team Name</h6>
                                    <p>{selectedTeam.team_name}</p>
                                </div>

                                <div className="team-name">
                                    <h6>Total Players</h6>
                                    <p>{selectedTeam.total_players} Players</p>
                                </div>
                            </div>

                            {isLocked ?
                                (
                                    ''
                                ) : (
                                    <div className="team-name team-link-main">
                                        <a id="linkToCopy" className='mb-3' href={link} style={{ pointerEvents: 'none' }}>Team Invite Link</a>

                                        <button id="copyButton" className='edit-butt' onClick={() => copyLink(link)}>Copy</button>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </section>

            <section className='view-table-main'>
                <div className="upper-btn-create d-flex">
                    <h3 className='main-head-pro'>Players Lobby</h3>
                </div>
                <div className="upper-my-tournament-table">
                    <table>
                        <thead>
                            <tr>
                                <th>Player Name</th>
                                <th>Role</th>
                                {/* <th>Gamer ID</th>
                                <th>Invitation Date</th>
                                <th>Invitation Time</th> */}
                                <th>PSN ID</th>
                                <th>Activision ID</th>
                                <th>Xbox GamerTag</th>
                                <th>EA ID</th>
                                <th>Ubisoft ID</th>
                                <th>Discord ID</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {playerLobbyList.map((player, index) => (
                                <tr key={index}>
                                    <td>{player.name}</td>
                                    <td>{player.is_accepted == 2 ? 'Captain' : 'Player'}</td>
                                    {/* <td>{player.user_details.service ? player.user_details.service : 'N/A'}</td>
                                    <td>{moment(player.created_at).format('DD-MM-YYYY')}</td>
                                    <td>{moment(player.created_at).format('HH:mm')}</td> */}
                                    <td>{player.user_details.psn ? player.user_details.psn : '-'}</td>
                                    <td>{player.user_details.activision ? player.user_details.activision : '-'}</td>
                                    <td>{player.user_details.xbox ? player.user_details.xbox : '-'}</td>
                                    <td>{player.user_details.ea ? player.user_details.ea : '-'}</td>
                                    <td>{player.user_details.ubisoft ? player.user_details.ubisoft : '-'}</td>
                                    <td>{player.user_details.discord ? player.user_details.discord : '-'}</td>

                                    {player.is_accepted == 2 ?
                                        <>
                                            <td>-</td>
                                            <td>-</td>
                                        </> :
                                        isLocked ?
                                            (
                                                <>
                                                    <td>
                                                        <div className="upper-div-lock">
                                                            <div className="upper-back-color">
                                                                <FontAwesomeIcon icon={faLock} />
                                                            </div>
                                                            <button className='accept-btn' disabled >Accepted</button>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="upper-div-lock">
                                                            <div className="upper-back-color">
                                                                <FontAwesomeIcon icon={faLock} />
                                                            </div>
                                                            <button className='pending-btn' disabled>Remove</button>
                                                        </div>
                                                    </td>
                                                </>
                                            )
                                            :
                                            (
                                                <>
                                                    <td>
                                                        {player.is_accepted == 1 ? (
                                                            <button className='accept-btn' disabled >
                                                                Accepted
                                                            </button>
                                                        ) : (
                                                            <button className='pending-btn' onClick={() => openModel(player)}>
                                                                Pending
                                                            </button>
                                                        )}
                                                    </td>
                                                    <td><button className='pending-btn' onClick={() => removeModal(player.id)}>Remove</button></td>
                                                </>
                                            )
                                    }
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </section>
            <Modal className="modal-logout" show={showRemoveModal} onHide={() => setShowRemove(false)}>
                <Modal.Body className="modal-padd">
                    <div className="inner-ogout-btn">
                        <h2>Are You sure you want to remove player ?</h2>
                        <p className="text-damble-text">
                            The action you are going to perform is irreversible please
                            Confirm! Are you sure that you want to remove player?
                        </p>
                    </div>
                    <div className="upper-btn-llogout-main d-flex">
                        <Link onClick={() => setShowRemove(false)} className="login-head">
                            Close
                        </Link>
                        <a className="edit-butt" onClick={() => { removePlayer(modalId); setShowRemove(false) }}>
                            Remove Player
                        </a>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default TeammanageView